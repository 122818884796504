var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-spin",
    {
      staticClass: "my-task",
      style: _vm.backCss,
      attrs: { spinning: _vm.loading },
    },
    [
      _c("div", { staticClass: "confirm-list-content" }, [
        _c("div", { staticClass: "confirm-list-top" }, [
          _c(
            "div",
            [
              _c(
                "a-radio-group",
                {
                  model: {
                    value: _vm.radioValue,
                    callback: function ($$v) {
                      _vm.radioValue = $$v
                    },
                    expression: "radioValue",
                  },
                },
                [
                  _vm.positions.some(
                    (p) => p.name == "校对" || p.name == "翻译"
                  )
                    ? _c("a-radio", { attrs: { value: 1 } }, [
                        _vm._v("\n            确认列表\n          "),
                      ])
                    : _vm._e(),
                  _vm.positions.some(
                    (p) => p.name == "校对" || p.name == "终审"
                  )
                    ? _c("a-radio", { attrs: { value: 2 } }, [
                        _vm._v("\n            其他职位待确认\n          "),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            [
              _vm.positions.some((p) => p.name == "翻译") &&
              _vm.positions.every((p) => p.name != "终审")
                ? _c(
                    "a-button",
                    {
                      staticClass: "mr-3",
                      attrs: {
                        disabled:
                          !_vm.tSelectionRows.length ||
                          _vm.tSelectionRows.some(
                            (item) => item.status == "未确认"
                          ),
                      },
                      on: { click: _vm.tBatchReject },
                    },
                    [_vm._v("\n          取消确认\n        ")]
                  )
                : _c(
                    "a-button",
                    {
                      staticClass: "mr-3",
                      attrs: {
                        disabled:
                          !_vm.pSelectionRows.length ||
                          _vm.pSelectionRows.some(
                            (item) => item.status == "未确认"
                          ),
                      },
                      on: { click: _vm.pBatchReject },
                    },
                    [_vm._v("\n          取消确认\n        ")]
                  ),
              _vm.positions.some((p) => p.name == "翻译") &&
              _vm.positions.every((p) => p.name != "终审")
                ? _c(
                    "a-button",
                    {
                      attrs: {
                        disabled:
                          !_vm.tSelectionRows.length ||
                          _vm.tSelectionRows.some(
                            (item) => item.status == "已确认"
                          ),
                        type: "primary",
                      },
                      on: { click: _vm.tBatchConfirm },
                    },
                    [_vm._v("\n          确认\n        ")]
                  )
                : _c(
                    "a-button",
                    {
                      attrs: {
                        disabled:
                          !_vm.pSelectionRows.length ||
                          _vm.pSelectionRows.some(
                            (item) => item.status == "已确认"
                          ),
                        type: "primary",
                      },
                      on: { click: _vm.pBatchConfirm },
                    },
                    [_vm._v("\n          确认\n        ")]
                  ),
            ],
            1
          ),
        ]),
        !_vm.loading
          ? _c(
              "div",
              { staticClass: "confirm-list-bottom" },
              [
                _c("a-table", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.positions.some((p) => p.name == "翻译") &&
                        _vm.positions.every((p) => p.name != "终审") &&
                        _vm.radioValue == 1,
                      expression:
                        "positions.some(p => p.name == '翻译') && positions.every(p => p.name != '终审') && radioValue == 1",
                    },
                  ],
                  attrs: {
                    rowKey: (record) => record.id,
                    scroll: {
                      x: "max-content",
                      y: "calc(100vh - 400px)",
                      scrollToFirstRowOnChange: true,
                    },
                    columns: _vm.translateColumns,
                    rowSelection: {
                      selectedRowKeys: _vm.tSelectedRowKeys,
                      onChange: _vm.ontSelectChange,
                      columnWidth: 45,
                    },
                    pagination: {
                      ..._vm.translateIpagination,
                      total: _vm.translatorConfirmList.length,
                    },
                    "data-source": _vm.translatorConfirmList,
                  },
                  on: { change: _vm.tHandleChange },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "filterIcon",
                        fn: function (filtered) {
                          return _c("span", {
                            staticClass: "iconfont icon-shaixuan icon",
                            style: {
                              color: filtered ? "#108ee9" : undefined,
                              fontSize: "16px",
                            },
                          })
                        },
                      },
                      {
                        key: "productionName",
                        fn: function (text, record) {
                          return _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    record.productionSequenceNum +
                                      "-" +
                                      record.productionName +
                                      "-" +
                                      record.chapterSequenceNum
                                  ) +
                                  "\n        "
                              ),
                            ]
                          )
                        },
                      },
                      {
                        key: "status",
                        fn: function (text, record) {
                          return _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticStyle: { "padding-left": "8px" } },
                                [_vm._v(_vm._s(record.status))]
                              ),
                            ]
                          )
                        },
                      },
                      {
                        key: "taskStatus",
                        fn: function (text, record) {
                          return _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticStyle: { "padding-left": "8px" } },
                                [_vm._v(_vm._s(record.taskStatus))]
                              ),
                            ]
                          )
                        },
                      },
                      {
                        key: "taskKpi",
                        fn: function (text, record) {
                          return _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                [
                                  record.taskKpi.hardAmount &&
                                  record.taskKpi.hardAmount > 0
                                    ? _c("a-tag", [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s("硬伤数") +
                                            " ： " +
                                            _vm._s(record.taskKpi.hardAmount) +
                                            "\n            "
                                        ),
                                      ])
                                    : _vm._e(),
                                  record.taskKpi.softAmount &&
                                  record.taskKpi.softAmount > 0
                                    ? _c("a-tag", [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s("软伤数") +
                                            " ： " +
                                            _vm._s(record.taskKpi.softAmount) +
                                            "\n            "
                                        ),
                                      ])
                                    : _vm._e(),
                                  record.taskKpi.errorAmount &&
                                  record.taskKpi.errorAmount > 0
                                    ? _c("a-tag", [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s("错翻数") +
                                            " ： " +
                                            _vm._s(record.taskKpi.errorAmount) +
                                            "\n            "
                                        ),
                                      ])
                                    : _vm._e(),
                                  record.taskKpi.firstModHardAmount &&
                                  record.taskKpi.firstModHardAmount > 0
                                    ? _c("a-tag", [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s("1改硬伤数") +
                                            " ： " +
                                            _vm._s(
                                              record.taskKpi.firstModHardAmount
                                            ) +
                                            "\n            "
                                        ),
                                      ])
                                    : _vm._e(),
                                  record.taskKpi.secondModHardAmount &&
                                  record.taskKpi.secondModHardAmount > 0
                                    ? _c("a-tag", [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s("2改硬伤数") +
                                            " ： " +
                                            _vm._s(
                                              record.taskKpi.secondModHardAmount
                                            ) +
                                            "\n            "
                                        ),
                                      ])
                                    : _vm._e(),
                                  record.taskKpi.multipleHardAmount &&
                                  record.taskKpi.multipleHardAmount > 0
                                    ? _c("a-tag", [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s("2改以上硬伤数") +
                                            " ： " +
                                            _vm._s(
                                              record.taskKpi.multipleHardAmount
                                            ) +
                                            "\n            "
                                        ),
                                      ])
                                    : _vm._e(),
                                  record.taskKpi.superSoftAmount &&
                                  record.taskKpi.superSoftAmount > 0
                                    ? _c("a-tag", [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s("超级软伤") +
                                            " ： " +
                                            _vm._s(
                                              record.taskKpi.superSoftAmount
                                            ) +
                                            "\n            "
                                        ),
                                      ])
                                    : _vm._e(),
                                  record.taskKpi.superHardAmount &&
                                  record.taskKpi.superHardAmount > 0
                                    ? _c("a-tag", [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s("超级硬伤") +
                                            " ： " +
                                            _vm._s(
                                              record.taskKpi.superHardAmount
                                            ) +
                                            "\n            "
                                        ),
                                      ])
                                    : _vm._e(),
                                  record.taskKpi.hasKpiModifies &&
                                  record.taskKpi.kpiModifyRatio > 0
                                    ? _c("a-tag", [
                                        _vm._v(
                                          "\n              修改率 :\n              "
                                        ),
                                        _c(
                                          "span",
                                          {
                                            style: record.taskKpi.textAmount
                                              ? ""
                                              : "color:red",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                record.taskKpi.textAmount
                                                  ? record.taskKpi
                                                      .kpiModifyRatio + "%"
                                                  : "X"
                                              )
                                            ),
                                          ]
                                        ),
                                      ])
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          )
                        },
                      },
                      {
                        key: "action",
                        fn: function (text, record) {
                          return _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                },
                              },
                            },
                            [
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.goToTask(record)
                                    },
                                  },
                                },
                                [_vm._v("查看详情")]
                              ),
                              record.status == "未确认"
                                ? _c(
                                    "a",
                                    {
                                      staticStyle: { "margin-left": "15px" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleConfirm(record)
                                        },
                                      },
                                    },
                                    [_vm._v("确认")]
                                  )
                                : _c(
                                    "a",
                                    {
                                      staticStyle: { "margin-left": "15px" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleReject(record)
                                        },
                                      },
                                    },
                                    [_vm._v("取消确认")]
                                  ),
                            ]
                          )
                        },
                      },
                    ],
                    null,
                    false,
                    4941114
                  ),
                }),
                _c("a-table", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.positions.some((p) => p.name == "翻译") &&
                        _vm.positions.some((p) => p.name == "终审") &&
                        _vm.radioValue == 1,
                      expression:
                        "positions.some(p => p.name == '翻译') && positions.some(p => p.name == '终审') && radioValue == 1",
                    },
                  ],
                  attrs: {
                    rowKey: (record) => record.id,
                    scroll: {
                      x: "max-content",
                      y: "calc(100vh - 400px)",
                      scrollToFirstRowOnChange: true,
                    },
                    columns: _vm.translateColumns,
                    rowSelection: {
                      selectedRowKeys: _vm.pSelectedRowKeys,
                      onChange: _vm.onpSelectChange,
                      columnWidth: 45,
                    },
                    pagination: {
                      ..._vm.p1Ipagination,
                      total: _vm.proofreaderConfirmList.length,
                    },
                    "data-source": _vm.proofreaderConfirmList,
                  },
                  on: { change: _vm.p1HandleChange },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "filterIcon",
                        fn: function (filtered) {
                          return _c("span", {
                            staticClass: "iconfont icon-shaixuan icon",
                            style: {
                              color: filtered ? "#108ee9" : undefined,
                              fontSize: "16px",
                            },
                          })
                        },
                      },
                      {
                        key: "productionName",
                        fn: function (text, record) {
                          return _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    record.productionSequenceNum +
                                      "-" +
                                      record.productionName +
                                      "-" +
                                      record.chapterSequenceNum
                                  ) +
                                  "\n        "
                              ),
                            ]
                          )
                        },
                      },
                      {
                        key: "status",
                        fn: function (text, record) {
                          return _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticStyle: { "padding-left": "8px" } },
                                [_vm._v(_vm._s(record.status))]
                              ),
                            ]
                          )
                        },
                      },
                      {
                        key: "taskStatus",
                        fn: function (text, record) {
                          return _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticStyle: { "padding-left": "8px" } },
                                [_vm._v(_vm._s(record.taskStatus))]
                              ),
                            ]
                          )
                        },
                      },
                      {
                        key: "action",
                        fn: function (text, record) {
                          return _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                },
                              },
                            },
                            [
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.goToTask(record)
                                    },
                                  },
                                },
                                [_vm._v("查看详情")]
                              ),
                              record.status == "未确认"
                                ? _c(
                                    "a",
                                    {
                                      staticStyle: { "margin-left": "15px" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleConfirm(record)
                                        },
                                      },
                                    },
                                    [_vm._v("确认")]
                                  )
                                : _c(
                                    "a",
                                    {
                                      staticStyle: { "margin-left": "15px" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleReject(record)
                                        },
                                      },
                                    },
                                    [_vm._v("取消确认")]
                                  ),
                            ]
                          )
                        },
                      },
                      {
                        key: "taskKpi",
                        fn: function (text, record) {
                          return _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                [
                                  record.taskKpi.hardAmount &&
                                  record.taskKpi.hardAmount > 0
                                    ? _c("a-tag", [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s("硬伤数") +
                                            " ： " +
                                            _vm._s(record.taskKpi.hardAmount) +
                                            "\n            "
                                        ),
                                      ])
                                    : _vm._e(),
                                  record.taskKpi.softAmount &&
                                  record.taskKpi.softAmount > 0
                                    ? _c("a-tag", [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s("软伤数") +
                                            " ： " +
                                            _vm._s(record.taskKpi.softAmount) +
                                            "\n            "
                                        ),
                                      ])
                                    : _vm._e(),
                                  record.taskKpi.errorAmount &&
                                  record.taskKpi.errorAmount > 0
                                    ? _c("a-tag", [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s("错翻数") +
                                            " ： " +
                                            _vm._s(record.taskKpi.errorAmount) +
                                            "\n            "
                                        ),
                                      ])
                                    : _vm._e(),
                                  record.taskKpi.firstModHardAmount &&
                                  record.taskKpi.firstModHardAmount > 0
                                    ? _c("a-tag", [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s("1改硬伤数") +
                                            " ： " +
                                            _vm._s(
                                              record.taskKpi.firstModHardAmount
                                            ) +
                                            "\n            "
                                        ),
                                      ])
                                    : _vm._e(),
                                  record.taskKpi.secondModHardAmount &&
                                  record.taskKpi.secondModHardAmount > 0
                                    ? _c("a-tag", [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s("2改硬伤数") +
                                            " ： " +
                                            _vm._s(
                                              record.taskKpi.secondModHardAmount
                                            ) +
                                            "\n            "
                                        ),
                                      ])
                                    : _vm._e(),
                                  record.taskKpi.multipleHardAmount &&
                                  record.taskKpi.multipleHardAmount > 0
                                    ? _c("a-tag", [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s("2改以上硬伤数") +
                                            " ： " +
                                            _vm._s(
                                              record.taskKpi.multipleHardAmount
                                            ) +
                                            "\n            "
                                        ),
                                      ])
                                    : _vm._e(),
                                  record.taskKpi.superSoftAmount &&
                                  record.taskKpi.superSoftAmount > 0
                                    ? _c("a-tag", [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s("超级软伤") +
                                            " ： " +
                                            _vm._s(
                                              record.taskKpi.superSoftAmount
                                            ) +
                                            "\n            "
                                        ),
                                      ])
                                    : _vm._e(),
                                  record.taskKpi.superHardAmount &&
                                  record.taskKpi.superHardAmount > 0
                                    ? _c("a-tag", [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s("超级硬伤") +
                                            " ： " +
                                            _vm._s(
                                              record.taskKpi.superHardAmount
                                            ) +
                                            "\n            "
                                        ),
                                      ])
                                    : _vm._e(),
                                  record.taskKpi.hasKpiModifies &&
                                  record.taskKpi.kpiModifyRatio > 0
                                    ? _c("a-tag", [
                                        _vm._v(
                                          "\n              修改率 :\n              "
                                        ),
                                        _c(
                                          "span",
                                          {
                                            style: record.taskKpi.textAmount
                                              ? ""
                                              : "color:red",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                record.taskKpi.textAmount
                                                  ? record.taskKpi
                                                      .kpiModifyRatio + "%"
                                                  : "X"
                                              )
                                            ),
                                          ]
                                        ),
                                      ])
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          )
                        },
                      },
                    ],
                    null,
                    false,
                    1984939130
                  ),
                }),
                _c("a-table", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.positions.some((p) => p.name == "校对") &&
                        _vm.positions.every((p) => p.name != "翻译") &&
                        _vm.radioValue == 1,
                      expression:
                        "positions.some(p => p.name == '校对') && positions.every(p => p.name != '翻译') && radioValue == 1",
                    },
                  ],
                  attrs: {
                    rowKey: (record) => record.sort,
                    scroll: {
                      x: "max-content",
                      y: "calc(100vh - 400px)",
                      scrollToFirstRowOnChange: true,
                    },
                    columns: _vm.p1Columns,
                    rowSelection: {
                      selectedRowKeys: _vm.pSelectedRowKeys,
                      onChange: _vm.onpSelectChange,
                      columnWidth: 45,
                    },
                    pagination: {
                      ..._vm.p1Ipagination,
                      total: _vm.proofreaderConfirmList.length,
                    },
                    "data-source": _vm.proofreaderConfirmList,
                  },
                  on: { change: _vm.p1HandleChange },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "filterIcon",
                        fn: function (filtered) {
                          return _c("span", {
                            staticClass: "iconfont icon-shaixuan icon",
                            style: {
                              color: filtered ? "#108ee9" : undefined,
                              fontSize: "16px",
                            },
                          })
                        },
                      },
                      {
                        key: "taskKpi",
                        fn: function (text, record) {
                          return _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                [
                                  record.taskKpi.hardAmount &&
                                  record.taskKpi.hardAmount > 0
                                    ? _c("a-tag", [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s("硬伤数") +
                                            " ： " +
                                            _vm._s(record.taskKpi.hardAmount) +
                                            "\n            "
                                        ),
                                      ])
                                    : _vm._e(),
                                  record.taskKpi.softAmount &&
                                  record.taskKpi.softAmount > 0
                                    ? _c("a-tag", [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s("软伤数") +
                                            " ： " +
                                            _vm._s(record.taskKpi.softAmount) +
                                            "\n            "
                                        ),
                                      ])
                                    : _vm._e(),
                                  record.taskKpi.errorAmount &&
                                  record.taskKpi.errorAmount > 0
                                    ? _c("a-tag", [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s("错翻数") +
                                            " ： " +
                                            _vm._s(record.taskKpi.errorAmount) +
                                            "\n            "
                                        ),
                                      ])
                                    : _vm._e(),
                                  record.taskKpi.firstModHardAmount &&
                                  record.taskKpi.firstModHardAmount > 0
                                    ? _c("a-tag", [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s("1改硬伤数") +
                                            " ： " +
                                            _vm._s(
                                              record.taskKpi.firstModHardAmount
                                            ) +
                                            "\n            "
                                        ),
                                      ])
                                    : _vm._e(),
                                  record.taskKpi.secondModHardAmount &&
                                  record.taskKpi.secondModHardAmount > 0
                                    ? _c("a-tag", [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s("2改硬伤数") +
                                            " ： " +
                                            _vm._s(
                                              record.taskKpi.secondModHardAmount
                                            ) +
                                            "\n            "
                                        ),
                                      ])
                                    : _vm._e(),
                                  record.taskKpi.multipleHardAmount &&
                                  record.taskKpi.multipleHardAmount > 0
                                    ? _c("a-tag", [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s("2改以上硬伤数") +
                                            " ： " +
                                            _vm._s(
                                              record.taskKpi.multipleHardAmount
                                            ) +
                                            "\n            "
                                        ),
                                      ])
                                    : _vm._e(),
                                  record.taskKpi.superSoftAmount &&
                                  record.taskKpi.superSoftAmount > 0
                                    ? _c("a-tag", [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s("超级软伤") +
                                            " ： " +
                                            _vm._s(
                                              record.taskKpi.superSoftAmount
                                            ) +
                                            "\n            "
                                        ),
                                      ])
                                    : _vm._e(),
                                  record.taskKpi.superHardAmount &&
                                  record.taskKpi.superHardAmount > 0
                                    ? _c("a-tag", [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s("超级硬伤") +
                                            " ： " +
                                            _vm._s(
                                              record.taskKpi.superHardAmount
                                            ) +
                                            "\n            "
                                        ),
                                      ])
                                    : _vm._e(),
                                  record.taskKpi.hasKpiModifies &&
                                  record.taskKpi.kpiModifyRatio > 0
                                    ? _c("a-tag", [
                                        _vm._v(
                                          "\n              修改率 :\n              "
                                        ),
                                        _c(
                                          "span",
                                          {
                                            style: record.taskKpi.textAmount
                                              ? ""
                                              : "color:red",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                record.taskKpi.textAmount
                                                  ? record.taskKpi
                                                      .kpiModifyRatio + "%"
                                                  : "X"
                                              )
                                            ),
                                          ]
                                        ),
                                      ])
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          )
                        },
                      },
                      {
                        key: "productionName",
                        fn: function (text, record) {
                          return _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    record.productionSequenceNum +
                                      "-" +
                                      record.productionName +
                                      "-" +
                                      record.chapterSequenceNum
                                  ) +
                                  "\n        "
                              ),
                            ]
                          )
                        },
                      },
                      {
                        key: "status",
                        fn: function (text, record) {
                          return _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticStyle: { "padding-left": "8px" } },
                                [_vm._v(_vm._s(record.status))]
                              ),
                            ]
                          )
                        },
                      },
                      {
                        key: "taskStatus",
                        fn: function (text, record) {
                          return _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticStyle: { "padding-left": "8px" } },
                                [_vm._v(_vm._s(record.taskStatus))]
                              ),
                            ]
                          )
                        },
                      },
                      {
                        key: "action",
                        fn: function (text, record) {
                          return _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                },
                              },
                            },
                            [
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.goToTask(record)
                                    },
                                  },
                                },
                                [_vm._v("查看详情")]
                              ),
                              record.status == "未确认"
                                ? _c(
                                    "a",
                                    {
                                      staticStyle: { "margin-left": "15px" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleConfirm(record)
                                        },
                                      },
                                    },
                                    [_vm._v("确认")]
                                  )
                                : _c(
                                    "a",
                                    {
                                      staticStyle: { "margin-left": "15px" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleReject(record)
                                        },
                                      },
                                    },
                                    [_vm._v("取消确认")]
                                  ),
                            ]
                          )
                        },
                      },
                    ],
                    null,
                    false,
                    936987162
                  ),
                }),
                _vm.positions.some((p) => p.name == "校对") &&
                _vm.radioValue == 2
                  ? _c("a-table", {
                      attrs: {
                        rowKey: (record) => record.id,
                        scroll: {
                          x: "max-content",
                          y: "calc(100vh - 400px)",
                          scrollToFirstRowOnChange: true,
                        },
                        columns: _vm.p2Columns,
                        pagination: {
                          ..._vm.p2Ipagination,
                          total: _vm.proofreaderToBeConfirmList.length,
                        },
                        "data-source": _vm.proofreaderToBeConfirmList,
                      },
                      on: { change: _vm.p2HandleChange },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "filterIcon",
                            fn: function (filtered) {
                              return _c("span", {
                                staticClass: "iconfont icon-shaixuan icon",
                                style: {
                                  color: filtered ? "#108ee9" : undefined,
                                  fontSize: "16px",
                                },
                              })
                            },
                          },
                          {
                            key: "productionName",
                            fn: function (text, record) {
                              return _c(
                                "span",
                                {
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(
                                        record.productionSequenceNum +
                                          "-" +
                                          record.productionName +
                                          "-" +
                                          record.chapterSequenceNum
                                      ) +
                                      "\n        "
                                  ),
                                ]
                              )
                            },
                          },
                          {
                            key: "taskKpi",
                            fn: function (text, record) {
                              return _c(
                                "span",
                                {
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    [
                                      record.taskKpi.hardAmount &&
                                      record.taskKpi.hardAmount > 0
                                        ? _c("a-tag", [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s("硬伤数") +
                                                " ： " +
                                                _vm._s(
                                                  record.taskKpi.hardAmount
                                                ) +
                                                "\n            "
                                            ),
                                          ])
                                        : _vm._e(),
                                      record.taskKpi.softAmount &&
                                      record.taskKpi.softAmount > 0
                                        ? _c("a-tag", [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s("软伤数") +
                                                " ： " +
                                                _vm._s(
                                                  record.taskKpi.softAmount
                                                ) +
                                                "\n            "
                                            ),
                                          ])
                                        : _vm._e(),
                                      record.taskKpi.errorAmount &&
                                      record.taskKpi.errorAmount > 0
                                        ? _c("a-tag", [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s("错翻数") +
                                                " ： " +
                                                _vm._s(
                                                  record.taskKpi.errorAmount
                                                ) +
                                                "\n            "
                                            ),
                                          ])
                                        : _vm._e(),
                                      record.taskKpi.firstModHardAmount &&
                                      record.taskKpi.firstModHardAmount > 0
                                        ? _c("a-tag", [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s("1改硬伤数") +
                                                " ： " +
                                                _vm._s(
                                                  record.taskKpi
                                                    .firstModHardAmount
                                                ) +
                                                "\n            "
                                            ),
                                          ])
                                        : _vm._e(),
                                      record.taskKpi.secondModHardAmount &&
                                      record.taskKpi.secondModHardAmount > 0
                                        ? _c("a-tag", [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s("2改硬伤数") +
                                                " ： " +
                                                _vm._s(
                                                  record.taskKpi
                                                    .secondModHardAmount
                                                ) +
                                                "\n            "
                                            ),
                                          ])
                                        : _vm._e(),
                                      record.taskKpi.multipleHardAmount &&
                                      record.taskKpi.multipleHardAmount > 0
                                        ? _c("a-tag", [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s("2改以上硬伤数") +
                                                " ： " +
                                                _vm._s(
                                                  record.taskKpi
                                                    .multipleHardAmount
                                                ) +
                                                "\n            "
                                            ),
                                          ])
                                        : _vm._e(),
                                      record.taskKpi.superSoftAmount &&
                                      record.taskKpi.superSoftAmount > 0
                                        ? _c("a-tag", [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s("超级软伤") +
                                                " ： " +
                                                _vm._s(
                                                  record.taskKpi.superSoftAmount
                                                ) +
                                                "\n            "
                                            ),
                                          ])
                                        : _vm._e(),
                                      record.taskKpi.superHardAmount &&
                                      record.taskKpi.superHardAmount > 0
                                        ? _c("a-tag", [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s("超级硬伤") +
                                                " ： " +
                                                _vm._s(
                                                  record.taskKpi.superHardAmount
                                                ) +
                                                "\n            "
                                            ),
                                          ])
                                        : _vm._e(),
                                      record.taskKpi.hasKpiModifies &&
                                      record.taskKpi.kpiModifyRatio > 0
                                        ? _c("a-tag", [
                                            _vm._v(
                                              "\n              修改率 :\n              "
                                            ),
                                            _c(
                                              "span",
                                              {
                                                style: record.taskKpi.textAmount
                                                  ? ""
                                                  : "color:red",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    record.taskKpi.textAmount
                                                      ? record.taskKpi
                                                          .kpiModifyRatio + "%"
                                                      : "X"
                                                  )
                                                ),
                                              ]
                                            ),
                                          ])
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            },
                          },
                          {
                            key: "status",
                            fn: function (text, record) {
                              return _c(
                                "span",
                                {
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { "padding-left": "8px" } },
                                    [_vm._v(_vm._s(record.status))]
                                  ),
                                ]
                              )
                            },
                          },
                          {
                            key: "taskStatus",
                            fn: function (text, record) {
                              return _c(
                                "span",
                                {
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { "padding-left": "8px" } },
                                    [_vm._v(_vm._s(record.taskStatus))]
                                  ),
                                ]
                              )
                            },
                          },
                        ],
                        null,
                        false,
                        3238486880
                      ),
                    })
                  : _vm.positions.some((p) => p.name == "终审") &&
                    _vm.positions.some((p) => p.name == "翻译") &&
                    _vm.radioValue == 2
                  ? _c("a-table", {
                      attrs: {
                        rowKey: (record) => record.id,
                        scroll: {
                          x: "max-content",
                          y: "calc(100vh - 400px)",
                          scrollToFirstRowOnChange: true,
                        },
                        columns: _vm.fColumns,
                        pagination: {
                          ..._vm.p2Ipagination,
                          total: _vm.proofreaderToBeConfirmList.length,
                        },
                        "data-source": _vm.proofreaderToBeConfirmList,
                      },
                      on: { change: _vm.fHandleChange },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "filterIcon",
                            fn: function (filtered) {
                              return _c("span", {
                                staticClass: "iconfont icon-shaixuan icon",
                                style: {
                                  color: filtered ? "#108ee9" : undefined,
                                  fontSize: "16px",
                                },
                              })
                            },
                          },
                          {
                            key: "taskKpi",
                            fn: function (text, record) {
                              return _c(
                                "span",
                                {
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    [
                                      record.taskKpi.hardAmount &&
                                      record.taskKpi.hardAmount > 0
                                        ? _c("a-tag", [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s("硬伤数") +
                                                " ： " +
                                                _vm._s(
                                                  record.taskKpi.hardAmount
                                                ) +
                                                "\n            "
                                            ),
                                          ])
                                        : _vm._e(),
                                      record.taskKpi.softAmount &&
                                      record.taskKpi.softAmount > 0
                                        ? _c("a-tag", [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s("软伤数") +
                                                " ： " +
                                                _vm._s(
                                                  record.taskKpi.softAmount
                                                ) +
                                                "\n            "
                                            ),
                                          ])
                                        : _vm._e(),
                                      record.taskKpi.errorAmount &&
                                      record.taskKpi.errorAmount > 0
                                        ? _c("a-tag", [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s("错翻数") +
                                                " ： " +
                                                _vm._s(
                                                  record.taskKpi.errorAmount
                                                ) +
                                                "\n            "
                                            ),
                                          ])
                                        : _vm._e(),
                                      record.taskKpi.firstModHardAmount &&
                                      record.taskKpi.firstModHardAmount > 0
                                        ? _c("a-tag", [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s("1改硬伤数") +
                                                " ： " +
                                                _vm._s(
                                                  record.taskKpi
                                                    .firstModHardAmount
                                                ) +
                                                "\n            "
                                            ),
                                          ])
                                        : _vm._e(),
                                      record.taskKpi.secondModHardAmount &&
                                      record.taskKpi.secondModHardAmount > 0
                                        ? _c("a-tag", [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s("2改硬伤数") +
                                                " ： " +
                                                _vm._s(
                                                  record.taskKpi
                                                    .secondModHardAmount
                                                ) +
                                                "\n            "
                                            ),
                                          ])
                                        : _vm._e(),
                                      record.taskKpi.multipleHardAmount &&
                                      record.taskKpi.multipleHardAmount > 0
                                        ? _c("a-tag", [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s("2改以上硬伤数") +
                                                " ： " +
                                                _vm._s(
                                                  record.taskKpi
                                                    .multipleHardAmount
                                                ) +
                                                "\n            "
                                            ),
                                          ])
                                        : _vm._e(),
                                      record.taskKpi.superSoftAmount &&
                                      record.taskKpi.superSoftAmount > 0
                                        ? _c("a-tag", [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s("超级软伤") +
                                                " ： " +
                                                _vm._s(
                                                  record.taskKpi.superSoftAmount
                                                ) +
                                                "\n            "
                                            ),
                                          ])
                                        : _vm._e(),
                                      record.taskKpi.superHardAmount &&
                                      record.taskKpi.superHardAmount > 0
                                        ? _c("a-tag", [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s("超级硬伤") +
                                                " ： " +
                                                _vm._s(
                                                  record.taskKpi.superHardAmount
                                                ) +
                                                "\n            "
                                            ),
                                          ])
                                        : _vm._e(),
                                      record.taskKpi.hasKpiModifies &&
                                      record.taskKpi.kpiModifyRatio > 0
                                        ? _c("a-tag", [
                                            _vm._v(
                                              "\n              修改率 :\n              "
                                            ),
                                            _c(
                                              "span",
                                              {
                                                style: record.taskKpi.textAmount
                                                  ? ""
                                                  : "color:red",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    record.taskKpi.textAmount
                                                      ? record.taskKpi
                                                          .kpiModifyRatio + "%"
                                                      : "X"
                                                  )
                                                ),
                                              ]
                                            ),
                                          ])
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            },
                          },
                          {
                            key: "productionName",
                            fn: function (text, record) {
                              return _c(
                                "span",
                                {
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(
                                        record.productionSequenceNum +
                                          "-" +
                                          record.productionName +
                                          "-" +
                                          record.chapterSequenceNum
                                      ) +
                                      "\n        "
                                  ),
                                ]
                              )
                            },
                          },
                          {
                            key: "taskStatus",
                            fn: function (text, record) {
                              return _c(
                                "span",
                                {
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { "padding-left": "8px" } },
                                    [_vm._v(_vm._s(record.taskStatus))]
                                  ),
                                ]
                              )
                            },
                          },
                          {
                            key: "status",
                            fn: function (text, record) {
                              return _c(
                                "span",
                                {
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { "padding-left": "8px" } },
                                    [_vm._v(_vm._s(record.status))]
                                  ),
                                ]
                              )
                            },
                          },
                        ],
                        null,
                        false,
                        2568174144
                      ),
                    })
                  : _vm.positions.some((p) => p.name == "终审") &&
                    _vm.positions.every(
                      (p) => p.name != "翻译" && p.name != "校对"
                    ) &&
                    _vm.radioValue == 2
                  ? _c("a-table", {
                      attrs: {
                        rowKey: (record) => record.sort,
                        scroll: {
                          x: "max-content",
                          y: "calc(100vh - 400px)",
                          scrollToFirstRowOnChange: true,
                        },
                        columns: _vm.fColumns,
                        pagination: {
                          ..._vm.fIpagination,
                          total: _vm.finalCheckTobeConfirmList.length,
                        },
                        "data-source": _vm.finalCheckTobeConfirmList,
                      },
                      on: { change: _vm.fHandleChange },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "filterIcon",
                            fn: function (filtered) {
                              return _c("span", {
                                staticClass: "iconfont icon-shaixuan icon",
                                style: {
                                  color: filtered ? "#108ee9" : undefined,
                                  fontSize: "16px",
                                },
                              })
                            },
                          },
                          {
                            key: "taskKpi",
                            fn: function (text, record) {
                              return _c(
                                "span",
                                {
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    [
                                      record.taskKpi.hardAmount &&
                                      record.taskKpi.hardAmount > 0
                                        ? _c("a-tag", [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s("硬伤数") +
                                                " ： " +
                                                _vm._s(
                                                  record.taskKpi.hardAmount
                                                ) +
                                                "\n            "
                                            ),
                                          ])
                                        : _vm._e(),
                                      record.taskKpi.softAmount &&
                                      record.taskKpi.softAmount > 0
                                        ? _c("a-tag", [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s("软伤数") +
                                                " ： " +
                                                _vm._s(
                                                  record.taskKpi.softAmount
                                                ) +
                                                "\n            "
                                            ),
                                          ])
                                        : _vm._e(),
                                      record.taskKpi.errorAmount &&
                                      record.taskKpi.errorAmount > 0
                                        ? _c("a-tag", [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s("错翻数") +
                                                " ： " +
                                                _vm._s(
                                                  record.taskKpi.errorAmount
                                                ) +
                                                "\n            "
                                            ),
                                          ])
                                        : _vm._e(),
                                      record.taskKpi.firstModHardAmount &&
                                      record.taskKpi.firstModHardAmount > 0
                                        ? _c("a-tag", [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s("1改硬伤数") +
                                                " ： " +
                                                _vm._s(
                                                  record.taskKpi
                                                    .firstModHardAmount
                                                ) +
                                                "\n            "
                                            ),
                                          ])
                                        : _vm._e(),
                                      record.taskKpi.secondModHardAmount &&
                                      record.taskKpi.secondModHardAmount > 0
                                        ? _c("a-tag", [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s("2改硬伤数") +
                                                " ： " +
                                                _vm._s(
                                                  record.taskKpi
                                                    .secondModHardAmount
                                                ) +
                                                "\n            "
                                            ),
                                          ])
                                        : _vm._e(),
                                      record.taskKpi.multipleHardAmount &&
                                      record.taskKpi.multipleHardAmount > 0
                                        ? _c("a-tag", [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s("2改以上硬伤数") +
                                                " ： " +
                                                _vm._s(
                                                  record.taskKpi
                                                    .multipleHardAmount
                                                ) +
                                                "\n            "
                                            ),
                                          ])
                                        : _vm._e(),
                                      record.taskKpi.superSoftAmount &&
                                      record.taskKpi.superSoftAmount > 0
                                        ? _c("a-tag", [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s("超级软伤") +
                                                " ： " +
                                                _vm._s(
                                                  record.taskKpi.superSoftAmount
                                                ) +
                                                "\n            "
                                            ),
                                          ])
                                        : _vm._e(),
                                      record.taskKpi.superHardAmount &&
                                      record.taskKpi.superHardAmount > 0
                                        ? _c("a-tag", [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s("超级硬伤") +
                                                " ： " +
                                                _vm._s(
                                                  record.taskKpi.superHardAmount
                                                ) +
                                                "\n            "
                                            ),
                                          ])
                                        : _vm._e(),
                                      record.taskKpi.hasKpiModifies &&
                                      record.taskKpi.kpiModifyRatio > 0
                                        ? _c("a-tag", [
                                            _vm._v(
                                              "\n              修改率 :\n              "
                                            ),
                                            _c(
                                              "span",
                                              {
                                                style: record.taskKpi.textAmount
                                                  ? ""
                                                  : "color:red",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    record.taskKpi.textAmount
                                                      ? record.taskKpi
                                                          .kpiModifyRatio + "%"
                                                      : "X"
                                                  )
                                                ),
                                              ]
                                            ),
                                          ])
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            },
                          },
                          {
                            key: "productionName",
                            fn: function (text, record) {
                              return _c(
                                "span",
                                {
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(
                                        record.productionSequenceNum +
                                          "-" +
                                          record.productionName +
                                          "-" +
                                          record.chapterSequenceNum
                                      ) +
                                      "\n        "
                                  ),
                                ]
                              )
                            },
                          },
                          {
                            key: "status",
                            fn: function (text, record) {
                              return _c(
                                "span",
                                {
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { "padding-left": "8px" } },
                                    [_vm._v(_vm._s(record.status))]
                                  ),
                                ]
                              )
                            },
                          },
                        ],
                        null,
                        false,
                        3975100724
                      ),
                    })
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }